import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from "gatsby"

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  const thumbnail = frontmatter.thumbnail
    ? frontmatter.thumbnail.childImageSharp.resize
    : null

  return (
    <Layout>
      <SEO article
        thumbnail={thumbnail}
        title={`${frontmatter.title} | Parsec Research`} 
        description={excerpt} />
      <div className="post-container">
        <div className="post">
          <h1>{frontmatter.title}</h1>
          <div>{frontmatter.author}</div>
          <div className="post-date">{frontmatter.date}</div>
          <div
            className="post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 150)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        author
        thumbnail {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`